// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mystuff-js": () => import("./../../../src/pages/mystuff.js" /* webpackChunkName: "component---src-pages-mystuff-js" */),
  "component---src-pages-responses-js": () => import("./../../../src/pages/responses.js" /* webpackChunkName: "component---src-pages-responses-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-testpage-js": () => import("./../../../src/pages/testpage.js" /* webpackChunkName: "component---src-pages-testpage-js" */)
}

